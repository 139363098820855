import Vue from "vue";

const types = {
  SET_USER: "SET_USER",
  SET_SNACKBAR_LOGIN: "SET_SNACKBAR_LOGIN",
  SET_REPORT: "SET_REPORT",
  SET_MODAL_REPORT: "SET_MODAL_REPORT",
};

const state = {
  user: {
    id: null,
    phone: "",
    token: "",
  },
  server: "https://app-mujer-backend-o4alj.ondigitalocean.app",
  // server: "http://192.168.1.89:8000",
  snackbarLogin: false,
  report: {},
  modalReport: false,
};

const actions = {
  login({ commit }, formData) {
    Vue.axios({
      method: "post",
      url: state.server + "/user/login-alter",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response.data);
        let newUser = {
          id: response.data.user.id,
          phone: response.data.user.phone,
          token: response.data.token.accessToken,
          permissions: response.data.user.permissions,
        };
        commit(types.SET_USER, newUser);
        localStorage.setItem("av_user", JSON.stringify(newUser));
      })
      .catch(function (error) {
        commit(types.SET_SNACKBAR_LOGIN, true);
      });
  },
  snackbarLogin({ commit }, data) {
    commit(types.SET_SNACKBAR_LOGIN, data);
  },
  logout({ commit }) {
    let newUser = {
      id: null,
      phone: "",
      token: "",
    };
    commit(types.SET_USER, newUser);
    window.localStorage.clear();
  },
  saveReport({ commit }, report) {
    commit(types.SET_REPORT, report);
  },
  setShowModal({ commit }, report) {
    commit(types.SET_MODAL_REPORT, report);
  },
  setUser({ commit }, user) {
    commit(types.SET_USER, user);
  },
};

const mutations = {
  [types.SET_USER](state, data) {
    state.user = data;
  },
  [types.SET_SNACKBAR_LOGIN](state, data) {
    state.snackbarLogin = data;
  },
  [types.SET_REPORT](state, data) {
    state.report = data;
  },
  [types.SET_MODAL_REPORT](state, data) {
    state.modalReport = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
