import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import langEs from "vuetify/es5/locale/es";

import moment from "moment";
moment.locale("es");

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#080705",
        secondary: "#1a237e",
        accent: "#9E54D9",
        error: "#F30D46",
        warning: "#FFA000",
        info: "#49C4CC",
        success: "#009F7E",
        globalGray: "#78909C",
        skin_tone_1: "#FFF2EA",
        skin_tone_2: "#FAE2D4",
        skin_tone_3: "#E3BDA9",
        skin_tone_4: "#A2795A",
        skin_tone_5: "#66422F",
        skin_tone_6: "#312725",
      },
    },
  },
  lang: {
    locales: {
      es: langEs,
    },
    current: "es",
  },
});
