import Vue from "vue";
import moment from "moment";

Vue.filter("time", function (value) {
  return moment(value).format("LT");
});

Vue.filter("date", function (value) {
  return moment(value).format("L");
});

Vue.filter("empty", function (value) {
  if (!!value) return value;
  return "-";
});

Vue.filter("datetime", function (value) {
  return moment(value)
    .format("DD/MMM/YYYY h:mm A")
    .toUpperCase()
    .replace(".", "");
});

Vue.filter("phone", function (phone) {
  if (phone == null || phone == "" || phone == undefined) return "Sin teléfono";
  return phone
    .replace(/[^0-9]/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
});

Vue.filter("date", function (value) {
  if (value) {
    return moment(String(value))
      .format("DD/MMM/YYYY HH:mm a")
      .toUpperCase()
      .replace(".", "");
  }
});

Vue.filter("birthdate", function (value) {
  if (value) {
    return moment(String(value))
      .format("DD/MMM/YYYY")
      .toUpperCase()
      .replace(".", "");
  }
});

Vue.filter("yearsold", function (value) {
  if (value) {
    value = moment(value, "YYYY/MM/DD").format("YYYY-MM-DD");
    return moment().diff(value, "years") + " años";
  }
});
