import Vue from "vue";

const types = {
  SET_EMERGENCIES: "SET_EMERGENCIES",
};

const state = {
  emergencies: [],
};

const actions = {
  setEmergencies({ commit }, data) {
    commit(types.SET_EMERGENCIES, data);
  },
};

const mutations = {
  [types.SET_EMERGENCIES](state, data) {
    state.emergencies = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
