import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./filters";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import Vue2Filters from "vue2-filters";

Vue.config.productionTip = false;

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
Vue.use(VueAxios, axios);
Vue.use(Vue2Filters);

new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
