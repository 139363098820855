import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: function () {
      return import(/* webpackChunkName: "reportes" */ "../views/Reports.vue");
    },
  },
  {
    path: "/login",
    name: "Login",
    component: function () {
      return import(/* webpackChunkName: "login" */ "../views/Login.vue");
    },
  },
  {
    path: "/reportes",
    name: "Reports",
    component: function () {
      return import(/* webpackChunkName: "reportes" */ "../views/Reports.vue");
    },
  },
  {
    path: "/usuarios",
    name: "Users",
    component: function () {
      return import(/* webpackChunkName: "usuarios" */ "../views/Users.vue");
    },
  },
  {
    path: "/detalles",
    name: "Details",
    component: function () {
      return import(/* webpackChunkName: "detalles" */ "../views/Details.vue");
    },
  },
  {
    path: "/admins",
    name: "Administradores",
    component: function () {
      return import(
        /* webpackChunkName: "detalles" */ "../views/Administrators.vue"
      );
    },
  },
  {
    path: "/create-report",
    name: "Crear reporte",
    component: function () {
      return import(
        /* webpackChunkName: "detalles" */ "../views/CreateReport.vue"
      );
    },
  },
  // {
  //   path: "/estadisticas",
  //   name: "Crear reporte",
  //   component: function () {
  //     return import(
  //       /* webpackChunkName: "detalles" */ "../views/Statistics.vue"
  //     );
  //   },
  // },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
