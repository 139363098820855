<template>
  <v-app class="font-family">
    <v-navigation-drawer app v-if="!!user.id" v-model="drawer">
      <v-list-item
        to="/"
        class="font-weight-medium"
        @click="drawer = false"
        v-if="!!user.permissions && user.permissions.includes('Reportes')"
      >
        <v-list-item-content>
          <v-list-item-title> Reportes </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        to="/usuarios"
        class="font-weight-medium"
        @click="drawer = false"
        v-if="!!user.permissions && user.permissions.includes('Policias')"
      >
        <v-list-item-content>
          <v-list-item-title> Policias </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        to="/admins"
        class="font-weight-medium"
        @click="drawer = false"
        v-if="
          !!user.permissions && user.permissions.includes('Administradores')
        "
      >
        <v-list-item-content>
          <v-list-item-title> Administradores </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn color="error" outlined rounded @click="logout" block>
            Cerrar sesión
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <h2>App sin violencia</h2>
      <v-spacer></v-spacer>
      <v-icon class="mx-2">mdi-shield-account</v-icon>
    </v-app-bar>

    <v-snackbar :value="snackbarLogin">
      Credenciales incorrectas, revise usuario y contraseña.
    </v-snackbar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<style>
.font-family {
  font-family: "Open Sans", sans-serif !important;
}
</style>
<script>
import { mapState } from "vuex";

export default {
  name: "App",
  mounted() {
    const av_user = localStorage.getItem("av_user");
    if (!!av_user) {
      this.saveUser(JSON.parse(av_user));
    } else {
      this.$router.replace({
        name: "Login",
      });
    }
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    ...mapState({
      server: (state) => state.cache.server,
      user: (state) => state.cache.user,
      snackbarLogin: (state) => state.cache.snackbarLogin,
    }),
  },
  methods: {
    hideSbackbarLogin() {
      this.$store.dispatch("cache/snackbarLogin", false);
    },
    saveUser(userData) {
      this.$store.dispatch("cache/setUser", userData);
    },
    logout() {
      this.$store.dispatch("cache/logout");
      localStorage.clear();
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>
