import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
import cache from "./cache";
import emergencies from "./emergencies";

Vue.use(Vuex);
Vue.config.productionTip = false;

const store = new Vuex.Store({
  // plugins: [createPersistedState()],
  modules: {
    cache,
    emergencies,
  },
});

export default store;
